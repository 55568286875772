@tailwind base;
@tailwind components;
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("ttf");
}
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
body {
  background-color: rgb(250, 250, 250);
}

/* Tiny MCE Styling*/
figure {
  display: table;
  margin: 1rem auto;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

figure.align-left {
  float: left;
}

figure.image.align-left {
  -webkit-user-modify: read-only;
}

figure figcaption {
  color: #999;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}

p {
  margin-block-end: 4px;
  margin-block-start: 4px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  text-align: justify;
  word-wrap: break-word;
}

/* */
