#countdown {
  position: relative;
  margin: auto;
  margin-top: 1rem;
  height: 96px; /* Increased font size, adjust height accordingly */
  width: 96px; /* Adjust width accordingly */
  text-align: center;
}

#countdown-number {
  color: #0e2e4a;
  display: inline-block;
  font-weight: 600;
  line-height: 87px; /* Match the height of the parent */
  font-size: 70px; /* Increased font size */
}

.svgBody {
  position: absolute;
  top: 0;
  left: 0; /* Adjusted left instead of right */
  width: 96px; /* Adjust width accordingly */
  height: 96px; /* Adjust height accordingly */
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.svgBody circle {
  stroke-dasharray: 301.59289474462014; /* Adjust stroke-dasharray accordingly (2 * π * r) */
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 5px; /* Adjust stroke-width accordingly */
  stroke: #0e2e4a;
  stroke-opacity: 80%;
  fill: none;
  animation: countdown 3s linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 301.59289474462014; /* Adjust stroke-dashoffset accordingly */
  }
}
